<template>
  <div>
    <Title :pages="[{ icon: 'desktop', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <div class="row mt-4 mb-2">
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="box-monitoring-mother">
              <div class="row">
                <div class="col box-monitoring divider">
                  <p>Pendientes</p>
                  <div v-if="!loading_monitoring">
                    {{ pending_orders }}
                  </div>
                  <div v-if="loading_monitoring" class="skeleton_monitoring">
                    <v-skeleton-loader style="display: inline-block; margin-top: 5px" v-if="loading_monitoring" type="chip" />
                  </div>
                </div>
                <div class="col box-monitoring divider">
                  <p>En ejecución</p>
                  <div v-if="!loading_monitoring">
                    {{ running_orders }}
                  </div>
                  <div v-if="loading_monitoring" class="skeleton_monitoring">
                    <v-skeleton-loader style="display: inline-block; margin-top: 5px" v-if="loading_monitoring" type="chip" />
                  </div>
                </div>

                <div class="col box-monitoring divider">
                  <p>Proximas a vencer</p>
                  <div v-if="!loading_monitoring">
                    {{ soon_expired_orders }}
                  </div>
                  <div v-if="loading_monitoring" class="skeleton_monitoring">
                    <v-skeleton-loader style="display: inline-block; margin-top: 5px" v-if="loading_monitoring" type="chip" />
                  </div>
                </div>

                <div class="col box-monitoring">
                  <p>Vencidas</p>
                  <div v-if="!loading_monitoring">
                    {{ expired_orders }}
                  </div>
                  <div v-if="loading_monitoring" class="skeleton_monitoring">
                    <v-skeleton-loader style="display: inline-block; margin-top: 5px" v-if="loading_monitoring" type="chip" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading_monitoring">
        <OrderSkeleton :loading="loading_monitoring" />
        <OrderSkeleton :loading="loading_monitoring" />
        <OrderSkeleton :loading="loading_monitoring" />
      </div>

      <div v-if="!loading_monitoring && work_orders.length > 0">
        <div class="col-md-12" v-for="order of work_orders" :key="order.id">
          <OrderMonitoring :order="order" />
        </div>
      </div>

      <div v-if="!loading_monitoring && work_orders.length == 0">
        <div class="col-md-12">
          <p class="text-center">
            <i style="font-size: 60px; color: #dd4b39; margin-top: 40px" class="fa fa-truck"></i>
          </p>
          <font-awesome-icon icon="chevron-down" class="cursor" style="color: rgb(52, 131, 250)" @click="menu = true" v-if="!menu" />
          <p class="alert-info-monitoring">Aun no hay ordenes en ejecucion</p>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { monitoringService } from "@/libs/ws/monitoringService";
import Title from "@/components/Title.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import OrderSkeleton from "./OrderSkeleton";
import OrderMonitoring from "./OrderMonitoring";
export default {
  mixins: [GenericMixin, RolMixin],
  components: {
    Title,
    OrderSkeleton,
    OrderMonitoring,
  },
  data() {
    return {
      page_title: "Monitoreo",
      loading_monitoring: false,
      work_orders: [],

      expired_orders: 0,
      soon_expired_orders: 0,
      pending_orders: 0,
      running_orders: 0,

      series01: [10, 5],
      chart01Options: {
        stroke: { show: false },
        colors: ["#5fba7d", "#7390fc"],
        legend: { show: false },
        dataLabels: { enabled: false },
        labels: ["Visitados", "En camino"],
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "70%",
              labels: { show: false },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getMonitoring();
    //this.updateEvery();
  },
  methods: {
    updateEvery() {
      this.interval = setInterval(
        function () {
          this.getMonitoring();
        }.bind(this),
        60000
      );
    },
    getMonitoring() {
      this.loading_monitoring = true;
      monitoringService
        .stats()
        .then((response) => {
          if (response.status) {
            this.work_orders = response.result.orders;
            this.pending_orders = response.result.pending_orders;
            this.pending_orders = response.result.pending_orders;
            this.running_orders = response.result.running_orders;
            this.expired_orders = response.result.expired_orders;
            this.soon_expired_orders = response.result.soon_expired_orders;
          }
          setTimeout(() => (this.loading_monitoring = false), 500);
        })
        .finally(() => {
          this.loading_monitoring = false;
        });
    },
  },
};
</script>
