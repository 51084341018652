<template>
  <div>
    <v-dialog v-model="open" max-width="500" @keydown.esc="$emit('cancel')">
      <v-card>
        <v-card-title class="headline">Exportar Libro IVA</v-card-title>

        <v-card-text>
          <p class="mt-5 mb-6 fs-16 text-center">Complete los siguientes datos para exportar el Libro IVA</p>
          <br />
          <div class="col-md-12 pt-0">
            <v-menu
              ref="desde_selected"
              v-model="desde_selected_open"
              :close-on-content-click="false"
              :close-on-click="false"
              :return-value.sync="desde_selected"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              clearable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
              </template>
              <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="desde_selected = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </div>

          <div class="col-md-12 pt-0">
            <v-menu
              ref="hasta_selected"
              v-model="hasta_selected_open"
              :close-on-content-click="false"
              :close-on-click="false"
              :return-value.sync="hasta_selected"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
              clearable
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
              </template>
              <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </div>

          <div class="col-md-12 pt-0">
            <v-combobox
              v-model="emitter_selected"
              autocomplete="off"
              data-vv-as="emitter_selected"
              name="emitter_selected"
              item-text="name"
              item-value="id"
              :items="emitters"
              label="Emisor"
              class="pt-0 mt-0"
              clearable
            ></v-combobox>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="$emit('cancel')"> Cancelar </v-btn>

          <v-btn color="green darken-1" text @click="exportLibroIVA" :loading="loading"> Exportar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { vouchersService } from "@/libs/ws/vouchersService";
export default {
  props: {
    open: Boolean,
    cancel: Function,
    accept: Function,
  },
  data: () => ({
    desde_selected_open: false,
    hasta_selected_open: false,
    desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
    hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
    emitter_selected: null,
    emitters: [],
    loading: false,
  }),
  watch: {
    // whenever question changes, this function will run
    open() {
      if (this.open) {
        this.emitter_selected = null;
      }
    },
  },
  mounted() {
    this.loadEmitters();
  },
  methods: {
    loadEmitters() {
      emmiterService.filter().then((response) => {
        this.emitters = response.result;
      });
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected + " 00:00:00",
        to: this.hasta_selected + " 23:59:59",
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
      };
      return param;
    },
    exportLibroIVA() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      //Validate mandatory filters: emitter, from, to
      if (!param.emitter || !param.from || !param.to) {
        this.$toast.error("Debe seleccionar un emisor, fecha desde y fecha hasta para exportar el libro de IVA.", "Error");
        this.loading = false;
        return;
      }

      vouchersService
        .export_libro_iva_filter(param)
        .then((response) => {
          console.log(response);
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "libro_iva.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
          this.$emit("cancel");
        })
        .catch((e) => {
          console.log("service.export_libro_iva_filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
