<script>
import Constant from "@/constants/sections";
export default {
  data() {
    return {
      roles: [],
      list_access: false,
      create_access: false,
      update_access: false,
      delete_access: false,
      sensible_access: false,
      sidebar_access: false,
    };
  },
  mounted() {
    this.getAccessArray();
    
  },
  methods: {
    getAccessArray() {
      
      this.roles = JSON.parse(this.CryptoJS.AES.decrypt(localStorage.getItem("access"), Constant.SECRET_PHRASE).toString(this.CryptoJS.enc.Utf8));      
      //var path = this.$route.path.replace("/", "");
      var path = this.$route.path.split('/')[1].split('/')[0];//Solo trae el primer key para determinar el modulo ex: /signatories/powers/1 -> signatories
            
      if (path in this.roles) {
        this.list_access = this.roles[path].list;
        this.create_access = this.roles[path].create;
        this.update_access = this.roles[path].update;
        this.delete_access = this.roles[path].delete;
        this.sensible_access = this.roles[path].sensible;
        this.sidebar_access = this.roles[path].sidebar;
      }                  
    },
  },
};
</script>
