const PLANTILLAS = {
    "COPIA YA CERTIFICADA": 'Dejo constancia que la presente certificación es de copia anteriormente certificada. Conste.-',
    "ASAMBLEAS - IGJ": 'La presente es copia fiel del Acta de Asamblea General Ordinaria Nº 18, celebrada con fecha 03/01/2018 y transcripta a los Folios 39, 40 y 41, del Libro de Actas de Asambleas N° 1, rubricado en la I.G.J. con fecha 24/08/2009, bajo el Nº 58838-09, perteneciente a la sociedad "ESTRUCTURAS Y MANDATOS S.A.". Conste.-',
    "DIRECTORIO - IGJ": 'La presente es copia fiel del Acta de Directorio Nº 86, celebrada con fecha 30/01/2018 y transcripta a los Folios 156 y 157, del Libro de Actas de Directorio N° 1, rubricado en la I.G.J. con fecha 24/08/2009, bajo el Nº 58839-09, perteneciente a la sociedad "ESTRUCTURAS Y MANDATOS S.A.". Conste.-',
    "REGISTRO DE ASISTENCIA – IGJ": 'La presente es copia fiel del Registro de Asistencia a Asamblea General, celebrada con fecha 11/01/2018 y transcripta al Folio 40 del Libro de Depósito de Acciones y Registro de Asistencia a Asambleas N° 2, rubricado en la I.G.J. con fecha 09/02/2015, bajo el Nº 7785-15, perteneciente a la sociedad "COHEN S.A.". Conste.-',
    "ASAMBLEA - DPPJ": 'La presente es copia fiel del Acta de Asamblea General Ordinaria de Accionistas Nº 33, celebrada con fecha 06/01/2020 y transcripta a los Folios 57 y 58, del Libro de Actas de Asambleas N° 1, rubricado en la D.P.P.J. con fecha 05/02/2008, bajo la Matrícula Nº 86457, Legajo Nº 154471, perteneciente a la sociedad "PROVINVEST S.A.". Conste.-',
    "DIRECTORIO – DPPJ": 'La presente es copia fiel del Acta de Directorio Nº 93, celebrada con fecha 03/02/2020 y transcripta a los Folios 3 y 4, del Libro de Actas de Directorio Nº 2, rubricado en la D.P.P.J. con fecha 03/02/2020, bajo la Matrícula Nº 86457, Legajo Nº 154471, perteneciente a la sociedad "PROVINVEST S.A.". Conste.-',
    "REGISTRO DE ASISTENCIA – DPPJ": 'La presente es copia fiel del Registro de Asistencia a Asamblea General Ordinaria, celebrada con fecha 06/01/2020 y transcripta al Folio 34, del Libro de Depósito de Acciones y Registro de Asistencia a Asambleas N° 1, rubricado en la D.P.P.J. con fecha 05/02/2008, bajo la Matrícula Nº  86457, Legajo Nº 154471, perteneciente a la sociedad "PROVINVEST S.A.". Conste.-',
    "OTRO TRÁMITE": 'Dejo constancia que la presente certificación corresponde al trámite de Radicación de Sociedad Extranjera inscripto en DPPJ bajo el Expediente: EXP-21209-223614/18/, Legajo 236675, Matrícula 136746, Resolucion 1576 de fecha 09/03/2018 y fecha de inscripción 13/03/2018 pertenciente a la sociedad: "VISTA OIL & GAS HOLDING I, SOCIEDAD ANONIMA DE CAPITAL VARIABLE". Conste.-',
    "PÁGINA DE INTERNET": 'Dejo constancia que la presente certificación es copia fiel de su original que tengo a la vista que obra en el día de la fecha en la siguiente página de internet: *****************Conste.-'
}

export default { 
    PLANTILLAS: PLANTILLAS
};
