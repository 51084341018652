import { wsService } from "./wsService";
import Constant from "@/constants/sections";

const section = Constant.SECTION_MONITORING;

function stats() {
  return wsService.make_post_request(section + "/stats");
}


export const monitoringService = {
  section,
  stats,  
};
