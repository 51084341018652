<template>
  <div>
    <v-dialog fullscreen v-model="dialog" @click:outside="closeModal()" @keydown.esc="closeModal()" v-on:input="closeModal()" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Impresion de foja de {{ task_name }}</v-card-title>

        <v-card-text style="background: #ecf0f5; height: calc(100vh - 110px); overflow: scroll">
          <div style="width: 1000px; margin: 0 auto; background: white; min-height: 95%" class="mt-5 elevation-2 p-5">
            <div v-if="step_0">
              <h5 class="pb-5"><b>Complete los campos para la impresion de foja</b></h5>

              <div class="row">
                <div class="col-md-12 d-flex">
                  <span class="fs-16 d-flex align-items-center pr-2">Buenos Aires,</span>
                  <div style="width: 80px">
                    <v-text-field dense data-vv-as="day" label="Día" name="day" v-model="foja.day" maxlength="2"></v-text-field>
                  </div>
                  <span class="fs-16 d-flex align-items-center pl-2 pr-2">de</span>
                  <div style="width: 180px">
                    <v-text-field dense data-vv-as="month" label="Mes" name="month" v-model="foja.month"></v-text-field>
                  </div>
                  <span class="fs-16 d-flex align-items-center pl-2 pr-2">de</span>
                  <div style="width: 130px">
                    <v-text-field dense data-vv-as="ano" label="Año" name="ano" v-model="foja.year"></v-text-field>
                  </div>
                  <span class="fs-16 d-flex align-items-center pl-2 pr-2">. En mi caracter de escribano</span>
                </div>
              </div>

              <div style="width: 780px">
                <v-text-field dense data-vv-as="record" label="" name="notary_record" v-model="foja.notary_record"></v-text-field>
              </div>

              <div class="row" v-if="!isCertificacionFirma()">
                <div class="col-md-12 d-flex">
                  <span class="fs-16 d-flex align-items-center pr-4">CERTIFICO: Que la reproduccion anexa, extendida en</span>
                  <div style="width: 480px">
                    <v-text-field dense data-vv-as="texto2" label="" name="texto2" v-model="foja.texto2"></v-text-field>
                  </div>
                </div>
              </div>

              <div class="row" v-if="!isCertificacionFirma()">
                <div class="col-md-12 d-flex">
                  <span class="fs-16 d-flex align-items-center">foja/s que sello y firmo, es COPIA FIEL de su original, que tengo a la vista, doy fe.</span>
                </div>
              </div>

              <div v-if="isCertificacionFotocopia()">
                <hr style="border: 0px; border-top: 3px dotted; margin-top: 40px !important; margin-bottom: 20px !important" v-if="isCertificacionFotocopia()" />
                <h5 style="margin-bottom: 20px !important"><b>Seleccione una plantilla</b></h5>
              </div>

              <div class="row" v-if="isCertificacionFotocopia()">
                <div class="col-md-12 d-flex">
                  <v-combobox
                    dense
                    v-model="plantilla_seleccionada"
                    data-vv-as="detail"
                    name="detail"
                    :items="plantillas"
                    item-text="name"
                    item-value="id"
                    label="Plantilla de foja"
                    autocomplete="off"
                    v-validate="'required'"
                    v-on:change="plantillaSelected()"
                  >
                  </v-combobox>
                </div>
              </div>

              <div class="row" v-if="!isCertificacionFirma()">
                <div class="col-md-12 d-flex">
                  <v-textarea rows="12" outlined v-model="foja.texto3" data-vv-as="texto3" name="texto3" label="Agregado"></v-textarea>
                </div>
              </div>

              <div class="row" v-if="isCertificacionFirma()">
                <div class="col-md-12 d-flex">
                  <span class="fs-16 d-flex align-items-center pr-4">CERTIFICO: Que la/s</span>
                  <div style="width: 480px">
                    <v-text-field dense data-vv-as="texto2" label="" name="texto2" v-model="foja.texto2"></v-text-field>
                  </div>
                  <span class="fs-16 d-flex align-items-center pl-4 pr-4">que obra/n en el</span>
                </div>
              </div>

              <div class="row" v-if="isCertificacionFirma()">
                <div class="col-md-12 d-flex">
                  <span class="fs-16 d-flex align-items-center pr-4">documento que adjunto a esta foja, cuyo requerimiento de certificaión se</span>
                </div>
              </div>

              <div class="row" v-if="isCertificacionFirma()">
                <div class="col-md-12 d-flex">
                  <span class="fs-16 d-flex align-items-center pr-4">formaliza simultaneamente por ACTA número</span>
                  <div style="width: 250px">
                    <v-combobox
                      dense
                      v-model="foja.detail"
                      data-vv-as="detail"
                      name="detail"
                      :items="getFojasComboBox()"
                      item-text="name"
                      item-value="id"
                      label="Nº Acta/Libro/foja"
                      autocomplete="off"
                      v-validate="'required'"
                      clearable
                      multiple
                      small-chips
                    >
                    </v-combobox>
                  </div>
                  <span class="fs-16 d-flex align-items-center pl-4 pr-4">del LIBRO...</span>
                </div>
              </div>

              <hr />
              <div class="row mt-5">
                <div class="col-md-6 d-flex">
                  <v-text-field dense data-vv-as="font_size" label="Tamaño de letra" name="font_size" v-model="font_size"></v-text-field>
                </div>
                <div class="col-md-6 d-flex">
                  <v-text-field dense data-vv-as="leading" label="Interlineado" name="leading" v-model="leading"></v-text-field>
                </div>
              </div>

              <div v-if="isCertificacionFirma()">
                <hr />
                <h5 class="mt-5 pb-5"><b>Seleccione las personerias que desea imprimir en la foja</b></h5>
                <v-btn color="success" class="mt-5" @click="newPersoneriaModal()">Caracter</v-btn>
                  <v-card class="mt-5" style="border: solid 1px #1c1c1c">
                    <v-card-title :style="{backgroundColor: '#FFF', border: 0 }" >
                      <v-spacer></v-spacer>
                      <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
                    </v-card-title>

                    <v-data-table
                      class="pb-4"
                      :headers="headers_signatories"
                      :items="personalities"                                            
                      :items-per-page="100"
                      :loading="loading"
                      loading-text="Cargando..."
                      v-model="selected"
                      show-select
                      :single-select="false"
                      :search="search"
                    >
                      <template v-slot:item="props">
                        <tr>
                          <td>
                            <v-checkbox
                              :disabled="props.item.expiration_date != null && new Date(props.item.expiration_date) < new Date()"
                              :input-value="props.isSelected"
                              style="margin: 0px; padding: 0px"
                              hide-details
                              @change="props.select($event)"
                            />
                          </td>
                          <td>{{ props.item.name }}</td>
                          <td>
                            <v-chip class="mr-1" v-for="obj in props.item.signatories" :key="obj.id" small> 
                              <span 
                                class="truncate"
                                :title="obj.alias"
                              >
                                {{ obj.alias }}
                              </span>                  
                            </v-chip>
                          </td>
                          <td :class="checkExpiredSignatory(props.item.expiration_date)">{{ props.item.expiration_date != null ? props.item.expiration_date : "-" }}</td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>
              </div>
            </div>

            <div v-else>
              <v-stepper v-model="actualStep" elevation-0 class="mt-5">
                <v-stepper-header class="headerstep">
                  
                  <template v-if="foja.detail.length != 1">
                    <v-stepper-step :complete="actualStep > 1" :step="1">Personerias</v-stepper-step>
                    <v-divider v-if="actualStep > 0"></v-divider>
                    <v-stepper-step :complete="actualStep > 2" :step="2">Impresíon de Fojas</v-stepper-step>                    
                  </template>

                  <template v-else>
                    <v-stepper-step :complete="actualStep > 1" :step="1">Personerias</v-stepper-step>
                    <v-divider v-if="actualStep != 1"></v-divider>
                  </template>
                </v-stepper-header>
                
                <v-stepper-content :step="1" class="height-modal-stepper">
                  <v-row no-gutters class="mt-4">
                    <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text @click="autoFill(PersonalityConstants.POR_SI)">1 - Por sí</v-btn></div>                  
                    <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text @click="autoFill(PersonalityConstants.REGISTRO_AUTOMOTOR)">2 - Registro automotor</v-btn></div>
                    <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text @click="autoFill(PersonalityConstants.IDIOMA_EXTRANJERO)">3 - Idioma extranjero</v-btn></div>
                  </v-row>
                  <v-row no-gutters class="mt-4 mb-4">
                    <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text @click="autoFill(PersonalityConstants.DIFIERA_FECHA)">4 - Difiera fecha</v-btn></div>
                    <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text @click="autoFill(PersonalityConstants.DIFIERA_LUGAR)">5 - Difiera lugar</v-btn></div>
                    <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text @click="autoFill(PersonalityConstants.DIFIERA_FECHA_LUGAR)">6 - Difiera fecha y lugar</v-btn></div>                    
                  </v-row>
                  <div class="text-left pt-3">
                    <v-textarea ref="description_textarea" rows="12" outlined v-model="personalities_text" data-vv-as="record" name="record" label="Descripción"></v-textarea>
                  </div>
                </v-stepper-content>
                
                <v-stepper-content v-if="foja.detail.length != 1" :step="2" class="height-modal-stepper">
                  <v-row class="d-flex text-center" v-for="(detail_foja,index) in foja.detail" :key="detail_foja.foja">
                    <v-col>
                      Acta Nº{{detail_foja.acta}} / Libro Nº{{detail_foja.libro}} / Foja Nº{{detail_foja.foja}} <v-btn :class="'primary ml-5 mb-1 '+getPrintBtnClass(index)" small :disabled="isCertificacionFirma() && selected.length == 0" :loading="getLoadingFojas(index)"  text @click="printFoja(index,true,false)">Imprimir</v-btn>
                    </v-col>
                  </v-row>                  
                </v-stepper-content>
              </v-stepper>
            </div>
          </div>
        </v-card-text>

        <v-spacer></v-spacer>
        <v-card-actions class="elevation-5" style="position: absolute; bottom: 0; background: #f4f4f4; width: 100%">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeModal()">Cerrar</v-btn>
          <v-btn v-if="!step_0" color="blue" text class="" @click="prevStep()">Atras</v-btn>
          <v-btn :disabled="(isCertificacionFirma() && selected.length == 0) || (isLastStep() && foja.detail.length > 1) || (foja.detail != undefined && foja.detail.length == 0)" :loading="loading_foja" color="green" text @click="step_0 ? step0Next() : nextStep()">
            {{ isLastStep() || !isCertificacionFirma() ? "Imprimir foja" : "Siguiente" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Modal
      :show_create_button="false"
      :button_name="$t('Crear') + ' Personería'"
      :modal_title="'Crear Personería'"
      :page_title="'Personerías'"
      :formBtn="'Crear'"
      :dialog="dialog_new_personeria"
      :parent="parent"
      :service="service_personeria"
      @updateDialog="closeModalPersoneria()"
      @loadList="getPersonalities()"
      :item="personalityItem"
      @updateItem="
        (newItem) => {
          personalityItem = newItem;
        }
      "
      :signatories="signatories"
      :signatories_selected="getSignatoriesIds()"
    />
  </div>
</template>
<script>
import ConstantSection from "@/constants/sections";
import PersonalityConstants from "@/constants/personality";
import { accessUtils } from "@/libs/utils/accessUtils";
import { generalService } from "@/libs/ws/generalService";
import { assignatesTaskService } from "@/libs/ws/assignatesTaskService";
import tasks_ids_with_foja from "@/constants/tasks_ids_with_foja";
import tasks_types from "@/constants/tasks_types";
import plantillasFoja from "@/constants/plantillasFoja";

import { SpanishConvertor } from "spanish-numbers";

import Modal from "@/views/Personalities/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    dialog: Boolean,
    signatories: Array,
    task_name: String,
    document: Object,
    asigned_task: Object,
    work_order: Object,
  },
  data: () => ({
    PersonalityConstants: PersonalityConstants,
    search: "",
    parent: this,
    dialog_new_personeria: false,
    loading: false,
    loading_foja: false,
    loading_fojas: [],
    actualStep: 1,
    step_0: true,
    personalities: [],
    details_printed: [],
    headers_signatories: [
      { text: "Personería", value: "name", sortable: false, class: "bg-grey" },
      { text: "Firmante", value: "signatories_names", sortable: false, class: "bg-grey" },
      { text: "Expiración", value: "expiration_date", sortable: false, class: "bg-grey" },
    ],
    selected: [],
    personalityItem: {
      id: "",
      name: "",
      description: "",
      signatories: null,
      expiration_date: null,
    },
    foja: {
      day: "",
      month: "",
      ano: "",
      notary_record: "",
      texto2: "",
      texto3: "",
    },
    defaultFoja: {
      day: "",
      month: "",
      ano: "",
      notary_record: "",
      texto2: "",
      texto3: "",
    },
    plantillas: Object.keys(plantillasFoja.PLANTILLAS),
    plantilla_seleccionada: null,
    font_size: 12,
    leading: 1.2,
    service_personeria: generalService,
    personalities_text: "",
  }),
  mounted() {
    this.service_personeria.setSection(ConstantSection.SECTION_PERSONALITIES);
  },
  watch: {
    dialog(value) {
      if (value) {
        this.initialize();
        this.getPersonalities();
      }
    },
  },
  methods: {
    getSignatoriesIds() {      
      if(Array.isArray(this.signatories) && this.signatories.length > 0){        
        return this.signatories.map((signatory) => signatory.id);
      }
      return [];      
    },
    initialize() {
      const date = new Date(this.work_order.applicant_date + " 10:00:00-03:00"); //Sin el agregado de la hora contaba un dia anterior
      this.foja.notary_record = this.getTexto1();
      this.foja.texto2 = this.getTexto2();
      this.foja.day = date.getDate();
      this.foja.year = date.getFullYear();
      this.foja.month = date.toLocaleString("default", { month: "long" });
      this.foja.detail = [this.asigned_task.details[0]];

      this.loading_fojas = [];
      this.asigned_task.details.map((detail) => {
        this.loading_fojas.push(false);
      });

      //Verificar si tiene fojas para seleccionar
      if (this.asigned_task.details.length == 0) this.$toast.error("La tarea no tiene asignado Nº Acta/Libro/foja.", "Error");
    },
    hasCreateAccessSignatory() {
      return accessUtils.getAccessOf(ConstantSection.SECTION_ORDERS_STATES).create;
    },
    closeModal() {
      this.actualStep = 1;
      this.personalities = [];
      this.selected = [];
      this.step_0 = true;
      this.foja = {
        day: "",
        month: "",
        year: "",
        notary_record: "",
        texto2: "",
        detail: [],
        personalities_text: ""
      };
      this.plantilla_seleccionada = null;
      this.details_printed = [];
      this.$emit("closeDialog");
    },
    hasFoja() {
      return this.asigned_task != null && tasks_ids_with_foja.includes(this.asigned_task.task.id);
    },
    isCertificacionFirma() {
      return this.asigned_task != null && this.asigned_task.task.id == tasks_types.CERTIFICACION_FIRMAS;
    },
    isCertificacionFotocopia() {
      return this.asigned_task != null && this.asigned_task.task.id == tasks_types.CERTIFICACION_FOTOCOPIAS;
    },
    getTexto1() {
      if (this.asigned_task != null && this.asigned_task.notary != null) {
        if (this.asigned_task.notary.record == null || this.asigned_task.notary.record == "") {
          this.$toast.warning('No se encontro el "Registro" del escribano, debe ingresarlo en el módulo de Empleados.', "Error");
          return "";
        }
        return this.asigned_task.notary.record;
      }
      this.$toast.error("La Tarea no posee escribano.", "Error");
      return "";
    },
    getTexto2() {
      if (this.isCertificacionFirma()) return this.signatories.length == 1 ? "firma" : "firmas";
      if (this.document.pages == 0) return this.document.pages + " (cero)";
      return this.document.pages + " (" + SpanishConvertor.convert(this.document.pages) + ")";
    },
    step0Next() {      
      if (this.isCertificacionFirma()) {
        //Juntar textos de personerias seleccionadas en this.selected
        this.personalities_text = "";
        this.selected.forEach((personality) => {
          this.personalities_text += personality.description + " ";
        });
        this.step_0 = false;
        return;
      }
      this.printFoja();
    },
    isLastStep() {      
      return !this.step_0 && this.foja.detail != undefined && (
        (this.foja.detail.length == 1 && this.actualStep == 1) || (this.foja.detail.length != 1 && this.actualStep == 2)
      );
    },
    nextStep() {
      if (this.foja.detail.length != 1 && this.actualStep == 1) {      
        this.actualStep = this.actualStep + 1;
        return;
      }
      this.printFoja();
    },
    prevStep() {
      if (this.actualStep == 1) this.step_0 = true;
      else this.actualStep = this.actualStep - 1;
    },
    printFoja(foja_detail_index = 0,open = true,close = true) {
      if(this.foja.detail.length == 1){
        this.loading_foja = true;
      }else{        
        this.$set(this.loading_fojas, foja_detail_index, true);
      }
            
      if (this.personalities_text.includes("FALTA_DNI_EN_FIRMANTE")) {
        this.$toast.error("Error, Falta el DNI en el firmante.", "Error");
        this.loading_foja = false;
        return;
      }
      
      

      if (this.foja.notary_record == "") this.foja.notary_record = " ";

      var foja = JSON.parse(JSON.stringify(this.foja));
      foja.detail = foja.detail[foja_detail_index];

      const params = {
        assigned_task_id: this.document.assigned_task,
        document_id: this.document.id,
        foja: foja,
        personalities: this.selected,
        personalities_text: this.personalities_text,
        font_size: this.font_size,
        leading: this.leading,
      };
      assignatesTaskService
        .printFoja(params)
        .then((response) => {          
          const textDecoder = new TextDecoder('utf-8');
          const resultString = textDecoder.decode(response.data);          
          try {
              const data = JSON.parse(resultString);
              console.log(data)
              this.$toast.error(data.msg, "Error");
              return;              
          } catch (e) {
            //ignore
          }
          
          let blob = new Blob([response.data], {
            type: "application/pdf",
          });
          if (open) {
            let blobURL = URL.createObjectURL(blob);
            window.open(blobURL);
          }else{
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            link.click();
          }

          this.details_printed.push(foja_detail_index);
          if(close)
            this.closeModal();
        })
        .catch((e) => {
          let json = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(e.response.data)));
          this.$toast.error(json.msg, "Error");
        })
        .finally(() => {          
          this.$set(this.loading_fojas, foja_detail_index, false);     
          this.loading_foja = false;
          console.log("printFoja END loading_fojas",this.loading_fojas[foja_detail_index],foja_detail_index);
        });
    },
    getPersonalities() {
      this.loading = true;
      const currentObj = this;
      this.personalities = [];
      if (this.signatories.length == 0 && this.asigned_task.task.id == tasks_types.CERTIFICACION_FIRMAS) {
        this.$toast.error("Los firmantes no poseen personerias.", "Error");
        this.loading = false;
        return;
      }
      
      generalService.setSection(ConstantSection.SECTION_PERSONALITIES);
      generalService
        .filter({signatories: this.signatories.map((signatory) => signatory.id)})
        .then((response) => {
          if (response.status) currentObj.personalities = currentObj.personalities.concat(response.result);
          else currentObj.$toast.error(response.msg, "Error");
        })
        .catch((e) => {
          console.log(e);
          currentObj.$toast.error(e.message, "Error");
        })
        .finally(() => (this.loading = false));
      
    },
    getFojasComboBox() {
      let fojas = [];
      if (this.asigned_task != null) this.asigned_task.details.forEach((detail) => fojas.push(detail));
      return fojas;
    },
    checkExpiredSignatory(expiration_date) {
      if (expiration_date == null) return "";
      return expiration_date != null && new Date(expiration_date) < new Date() ? "text-danger" : "";
    },
    getPlantillas() {
      return [];
    },
    plantillaSelected() {
      this.foja.texto3 = plantillasFoja.PLANTILLAS[this.plantilla_seleccionada];
    },
    newPersoneriaModal() {
      this.dialog_new_personeria = true;
    },
    closeModalPersoneria() {
      this.personalityItem = {
        id: "",
        name: "",
        description: "",
        signatories: null,
        expiration_date: null,
      };
      this.dialog_new_personeria = false;
    },
    getPrintBtnClass(index) {
      console.log(this.details_printed,index)
      return this.details_printed.includes(index) ? "secondary" : "";
    },
    getLoadingFojas(index) {
      console.log("getLoadingFojas loading_fojas", this.loading_fojas[index],index);
      return this.loading_fojas[index];
    },
    autoFill(key){          
      let description_textarea = this.$refs.description_textarea.$refs.input;
      const insertText = PersonalityConstants.getTextByKey(key);      
      let pos = description_textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }
      const sentence = description_textarea.value;      
      const len = sentence.length
      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)      

      this.personalities_text = before + insertText + after;      
      
      this.$nextTick().then(() => {
        description_textarea.selectionStart = pos + insertText.length
      })
      
    },
  },
};
</script>
