import { wsService } from "./wsService";

const section = "certification_orders";
const STATE_PENDING = 0;
const STATE_RUNNING = 1;
const STATE_FINISH = 2;

const STATE_ACCOUNTING_PENDING = 0;
const STATE_ACCOUNTING_UNBILLED = 1;
const STATE_ACCOUNTING_BILLED = 2;

function filter(param) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get_states(param = {}) {
  return wsService.make_get_request(section + "/states", param);
}

function print(order_id, type) {
  return wsService.make_get_request("print/order/" + order_id + "/" + type, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
    },
  });
}

function export_filter(params) {
  return wsService.make_post_request(section + "/export", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function exportDetalleOrdenesPorTarea(params){
  return wsService.make_post_request(section + "/export_detalle_ordenes_por_tareas", params, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  });
}

function getStateHtml(state) {
  switch (parseInt(state.id)) {
    case STATE_PENDING:
      return '<p class="state_pending">' + state.name + "</p>";
    case STATE_RUNNING:
      return '<p class="state_running">' + state.name + "</p>";
    case STATE_FINISH:
      return '<p class="state_finish">' + state.name + "</p>";
    default:
      return "-";
  }
}

function getAccountingStateHtml(state) {
  switch (parseInt(state.id)) {
    case STATE_ACCOUNTING_PENDING:
      return '<p class="state_accounting_pending">' + state.name + "</p>";
    case STATE_ACCOUNTING_UNBILLED:
      return '<p class="state_accounting_unbilled">' + state.name + "</p>";
    case STATE_ACCOUNTING_BILLED:
      return '<p class="state_accounting_billed">' + state.name + "</p>";
    default:
      return "-";
  }
}

function getDataToRequest(item) {
  var data = {
    expiration_date: item.expiration_date,
    employee_id: item.employee != null ? item.employee.id : 0,
    client_id: item.client.id,
    applicant_id: item.applicant != null ? item.applicant.id : null,
    id: item.id,
    asignated_tasks_arr: [],
    observaciones_internas: item.observaciones_internas,
    observaciones_cliente: item.observaciones_cliente,
    viewdIndex: -1,
    editedIndex: -1,
  };

  //Load asignated_tasks
  item.asignated_tasks.forEach((a_task) => {
    var asignated_task = {
      id: a_task.id,
      cargo_id: a_task.cargo != null ? a_task.cargo.id : null,
      task_id: a_task.task.id,
      notary_id: a_task.notary != null ? a_task.notary.id : 0,
      expiration_date: a_task.expiration_date,
      priority: a_task.priority,
      has_attached_files: a_task.has_attached_files,
      legalizacion: a_task.legalizacion,
      apostilla: a_task.apostilla,
      apostilla_urgente: a_task.apostilla_urgente,
      anexo: a_task.anexo,
      documents: [], //for
      signatories: [], //for
      details: [], //for
    };

    //Load details
    a_task.details.forEach((d) => {
      const detail = {
        acta: d.acta,
        libro: d.libro,
        foja: d.foja,
        id: d.id,
      };
      asignated_task.details.push(detail);
    });

    //Load documents
    a_task.documents.forEach((doc) => {
      var file = null;
      if (!Number.isInteger(doc.file) && doc.file != undefined) file = doc.file;

      const document = {
        name: doc.name,
        pages: doc.pages,
        file: file,
        id: doc.id,
      };
      asignated_task.documents.push(document);
    });

    //Load signatoryes
    a_task.signatories.forEach((sig) => {
      asignated_task.signatories.push(sig.id);
    });

    data.asignated_tasks_arr.push(asignated_task);
  });

  //console.log(data);
  return data;
}

export const orderService = {
  section,
  getStateHtml,
  getAccountingStateHtml,
  get_states,
  getDataToRequest,
  filter,
  remove,
  create,
  update,
  print,
  export_filter,
  exportDetalleOrdenesPorTarea,
};
