<template>
  <v-layout :class="center ? 'd-flex justify-content-center' : ''">
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" @keydown.esc="$emit('updateDialog', false)" max-width="1200">
      <template v-slot:activator="{ on }" v-if="show_create_button">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="openModal()">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>
          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox
                  v-model="item.signatories"
                  data-vv-as="signatories"
                  name="signatories"
                  item-text="alias"
                  item-value="id"
                  :items="signatories"
                  label="Firmantes*"
                  autocomplete="off"
                  v-validate="'required'"
                  :disabled="isViewModal()"
                  clearable
                  multiple
                  small-chips
                  @change="selectedSignatories"
                ></v-combobox>
                <span class="red--text">{{ errors.first("signatories") }}</span>

                <v-text-field data-vv-as="name" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>
                <DatePickerEsp
                  label="Fecha de expiración"
                  :initToday="false"
                  :date="item.expiration_date"
                  :isRequired="false"
                  name="expiration_date"
                  :isViewModal="isViewModal()"
                  @setDate="
                    (date) => {
                      item.expiration_date = date;
                    }
                  "
                />
                <v-row no-gutters class="mt-4">
                  <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text v-if="!isViewModal()" @click="autoFill(PersonalityConstants.POR_SI)">1 - Por sí</v-btn></div>                  
                  <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text v-if="!isViewModal()" @click="autoFill(PersonalityConstants.REGISTRO_AUTOMOTOR)">2 - Registro automotor</v-btn></div>
                  <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text v-if="!isViewModal()" @click="autoFill(PersonalityConstants.IDIOMA_EXTRANJERO)">3 - Idioma extranjero</v-btn></div>
                </v-row>
                <v-row no-gutters class="mt-4">
                  <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text v-if="!isViewModal()" @click="autoFill(PersonalityConstants.DIFIERA_FECHA)">4 - Difiera fecha</v-btn></div>
                  <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text v-if="!isViewModal()" @click="autoFill(PersonalityConstants.DIFIERA_LUGAR)">5 - Difiera lugar</v-btn></div>
                  <div class="col-md-4 pl-2 pr-2"><v-btn class="primary warning w-100" text v-if="!isViewModal()" @click="autoFill(PersonalityConstants.DIFIERA_FECHA_LUGAR)">6 - Difiera fecha y lugar</v-btn></div>                  
                </v-row>
                <v-textarea ref="description_textarea" class="mt-4" rows="12" outlined v-model="item.description" name="description" label="Descripción*" :disabled="isViewModal()"></v-textarea>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import PersonalityConstants from "@/constants/personality";
import { generalService } from "@/libs/ws/generalService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";

export default {
  name: "Modal",
  props: {
    fixed_client: {
      default: false,
      type: Boolean,
    },
    center: {
      default: false,
      type: Boolean,
    },
    show_create_button: {
      default: true,
      type: Boolean,
    },
    signatories_selected: {
      default: null,
      type: Array,
    }, 

    parent: Object,
    service: Object,
    modal_title: String,
    page_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,

    
  },
  components: { DatePickerEsp },
  data() {
    return {
      show: false,
      clients: [],
      loading: false,
      signatories: [],      
      PersonalityConstants: PersonalityConstants,
      old_signatories_count: 0,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.old_signatories_count = 0;      
      this.errors.clear();
      this.$validator.reset();
    },
    signatories_selected() {
      this.getSignatories();
    },
  },
  mounted() {
    this.getSignatories();
  },
  methods: {
    autoFill(key){
      let description_textarea = this.$refs.description_textarea.$refs.input;
      const insertText = PersonalityConstants.getTextByKey(key);      
      let pos = description_textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }
      const sentence = description_textarea.value;
      const len = sentence.length
      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)      

      this.item.description = before + insertText + after;
      this.$nextTick().then(() => {
        description_textarea.selectionStart = pos + insertText.length
      })
    },
    getSignatories() {
      generalService
        .filter({}, Constant.SECTION_SIGNATORIES)
        .then((response) => {    
          //Get only signatories from response.result where signatory.id is in signatories_selected
          
          if(this.signatories_selected != null && this.signatories_selected.length > 0){                         
            this.signatories = response.result.filter((signatory) => {
              return this.signatories_selected.includes(signatory.id);
            });            
          }else{      
            this.signatories = response.result;          
          }
        })
        .catch((e) => {
          console.log("getSignatories error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    selectedSignatories(signatories) {      
      if(this.old_signatories_count >= signatories.length){        
        this.old_signatories_count = signatories.length;
        return;
      }
      this.old_signatories_count = signatories.length;
      this.item.description = this.item.description.replace(PersonalityConstants.STANDARD_SIGNATORY_END_TEXT,"");
      this.item.description = this.item.description.replace(PersonalityConstants.STANDARD_SIGNATORIES_END_TEXT,"");
      if(signatories.length > 0){
        const signatory = signatories[signatories.length - 1];
        const contater = (signatories.length > 1)? " y " : " ";
        this.item.description = this.item.description  + contater + PersonalityConstants.getSignatoryText(signatory.name,signatory.dni);
      }

      if(signatories.length == 1){//Footer
        this.item.description = this.item.description + PersonalityConstants.STANDARD_SIGNATORY_END_TEXT;
      }else if(signatories.length > 1){
        this.item.description = this.item.description + PersonalityConstants.STANDARD_SIGNATORIES_END_TEXT;
      }
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          const data = {
            signatories_arr: this.item.signatories.map((item) => item.id),
            name: this.item.name,
            expiration_date: this.item.expiration_date,
            description: this.item.description,
            id: this.item.id,
          };

          if (this.item.id > 0) {
            this.loading = true;
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.page_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.loading = true;
            this.service
              .create(data)
              .then((response) => {
                if (response.result.id > 0) {
                  this.$toast.success("El " + this.page_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    openModal() {      
      this.$emit("updateDialog", true);
    },
  },
};
</script>
