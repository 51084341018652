<template>
  <div class="row">
    <div class="col-md-12">
      <v-toolbar color="#fc6803" dark dense app class="pl-5 elevation-0">
        <v-tooltip bottom :open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-app-bar-nav-icon v-bind="attrs" v-on="on" @click="handleSidebar"></v-app-bar-nav-icon>
          </template>
          <span>Menú principal</span>
        </v-tooltip>

        <div class="position-absolute d-flex align-items-center" style="right: 5px">
          <p class="mb-0 mr-2">{{ getUsername() }}</p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on:click="openLogoutModal()" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-export</v-icon>
              </v-btn>
            </template>
            <span>Cerrar sesión</span>
          </v-tooltip>
        </div>
      </v-toolbar>
    </div>

    <DialogYesNo :open="dialog_logout" title="Confirmar" message="¿Estas seguro que quieres cerrar sesion?" @cancel="dialog_logout = false" @accept="logoutConfirm" />
  </div>
</template>
<script>
import { authService } from "@/libs/ws/authService";
import DialogYesNo from "@/components/DialogYesNo.vue";
export default {
  name: "App",
  components: { DialogYesNo },
  data() {
    return {
      idiomas: [{ id: 0, name: "Lang_ES", code: "es" }],
      dialog_logout: false,
    };
  },
  mounted() {
    /* this.$i18n.locale = "en"; */
  },
  methods: {
    openLogoutModal() {
      this.dialog_logout = true;
    },
    handleSidebar() {
      if (!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
        this.$store.commit("setOpenSidebar", {
          mini: !this.$store.state.sidebar.mini,
          expand_on_hover: !this.$store.state.sidebar.mini,
          open: !this.$store.state.sidebar.open,
        });
      } else {
        this.$store.commit("setOpenSidebar", {
          mini: false,
          expand_on_hover: false,
          open: !this.$store.state.sidebar.open,
        });
      }
    },
    getUsername() {
      return localStorage.getItem("username");
    },
    logoutConfirm() {
      authService.logOut();
      this.$router.push("/login");
    },
    langSelector(name) {
      this.$i18n.locale = name;
      localStorage.setItem("lang", name);
      location.reload();
    },
  },
};
</script>
