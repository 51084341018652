import { wsService } from "./wsService";
import axios from "axios";
import Constant from "@/constants/sections";

const section = Constant.SECTION_STORAGE;
const api_url = process.env.VUE_APP_API_URL;

function downloadFile(filename, accept = "application/pdf", responseType = "arraybuffer") {
  //return wsService.make_get_request(section + filename,0,'application/pdf','arraybuffer');
  const token = localStorage.getItem("token");  
  return axios
      .get(api_url+section+filename, {
          responseType: responseType,
          headers: {
              'Accept': accept,
              "Content-Type": "application/json; charset=utf-8",
              'Authorization': "Bearer " + token,
          }
      })
}

function getFileUrl(filename) {
  return api_url+section+filename;      
}

export const filesService = {
  section,
  downloadFile,
  getFileUrl,
};
