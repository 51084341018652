const TYPE_EMPLOYEE = "employee";
const TYPE_NOTARY = "notary";

export default { 
    TYPE_EMPLOYEE: TYPE_EMPLOYEE,
    TYPE_NOTARY: TYPE_NOTARY,    

    TYPE_COMBO: [
        { id: TYPE_EMPLOYEE, name: "Empleado"},
        { id: TYPE_NOTARY, name: "Escribano"},
    ],
};
