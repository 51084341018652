const EMPLOYEE = "E";
const APPLICANT = "A";
const SUPERUSER = "S";


export default { 
    EMPLOYEE: EMPLOYEE,
    APPLICANT: APPLICANT,
    SUPERUSER: SUPERUSER,
};
