<template>
  <div>
    <Title :pages="[{ icon: 'file', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="open_generate_comprobante = true">Crear Comprobante</v-btn>

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="cbte_tipo_selected"
                    autocomplete="off"
                    data-vv-as="cbte_tipo_selected"
                    name="cbte_tipo_selected"
                    item-text="Desc"
                    item-value="Id"
                    :items="cbte_tipos"
                    label="Tipo de Comprobante"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="voucher_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Comprobante"></v-text-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="receiver_selected"
                    autocomplete="off"
                    data-vv-as="receiver_selected"
                    name="receiver_selected"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor"
                    class="pt-0 mt-0"
                    v-on:change="clientChangeCombo($event)"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    v-model="emitter_selected"
                    autocomplete="off"
                    data-vv-as="emitter_selected"
                    name="emitter_selected"
                    item-text="name"
                    item-value="id"
                    :items="emitters"
                    label="Emisor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="blue-grey mr-2" dark v-bind="attrs" v-on="on"> Exportar <font-awesome-icon class="ml-2" icon="caret-down" /></v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="exportToExcel">
                        <v-list-item-title>Exportar detalle de ventas por concepto</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="dialog_libro_iva = true">
                        <v-list-item-title>Exportar libro IVA</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.creation_date }}</td>
              <td>{{ props.item.cbte_tipo_name }}</td>
              <td>{{ props.item.voucher_number != null ? props.item.pto_vta.toString().padStart(4, "0") + "-" + props.item.voucher_number.toString().padStart(8, "0") : "-" }}</td>
              <td>{{ props.item.nro_order }}</td>
              <td>{{ props.item.emitter.name }}</td>
              <td>{{ props.item.receiver.name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td>{{ formatUtils.formatPrice(props.item.imp_total) }}</td>
              <td>{{ props.item.state.name }}</td>

              <td class="text-center px-0">
                <v-tooltip bottom v-if="props.item.cbtes_asoc.length > 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewcbtesAsoc(props.item)"> info </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <p v-else>-</p>
              </td>

              <td class="text-center px-0">
                <v-tooltip bottom v-if="props.item.state.id == 1">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="printItem(props.item)"> print </v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == 1 && props.item.is_invoice">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2" @click="cancelInvoice(props.item)"> close </v-icon>
                  </template>
                  <span>Anular</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="openDialogSendToAFIP(props.item)"> send </v-icon>
                  </template>
                  <span>Enviar a la AFIP</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogGenerarComprobante
        title="Generar Comprobante"
        :open="open_generate_comprobante"
        :invoice_to_cancel="invoice_to_cancel"
        @cancel="
          open_generate_comprobante = false;
          invoice_to_cancel = null;
        "
        @updateDialog="filter"
      />

      <DialogYesNo :title="title_dialog" :message="message_dialog" :open="dialog_open_afip" :loading="loading_dialog" @accept="sendToAFIP" @cancel="closeDialog" @reload="filter" />

      <DialogLoading :dialog="dialog_imprimiendo" text="Imprimiendo..." />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />

      <DialogExportLibroIVA :open="dialog_libro_iva" @cancel="dialog_libro_iva = false" />

      <CbtesAsocModal
        :open="dialog_cbtes_asoc"
        :item="voucher_selected"
        @cancel="
          dialog_cbtes_asoc = false;
          voucher_selected = null;
        "
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { receiversService } from "@/libs/ws/receiversService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogGenerarComprobante from "./DialogGenerarComprobante.vue";
import DialogYesNo from "@/components/DialogYesNo";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import DialogExportLibroIVA from "./DialogLibroIVA.vue";
import CbtesAsocModal from "./CbtesAsocModal.vue";

export default {
  components: { Title, DialogGenerarComprobante, DialogYesNo, DialogDelete, CbtesAsocModal, DialogLoading, DialogExportLibroIVA },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: vouchersService,
      page_title: "Comprobantes",
      modal_title: "Comprobante",
      mainItem: {
        id: "",
        creation_date: "",
        cbte_tipo: "",
        emitter: "",
        receiver: "",
        imp_tot_conc: "",
        imp_neto: "",
        imp_total: "",
      },
      dialog_libro_iva: false,
      dialog_delete: false,
      dialog_imprimiendo: false,
      dialog_open_afip: false,
      dialog_cbtes_asoc: false,
      voucher_selected: null,
      loading_dialog: false,
      title_dialog: "",
      message_dialog: "",
      item_dialog: null,
      defaultItem: null,
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "creation_date" },
        { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
        { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number" },
        { text: "Nº Orden", align: "left", sortable: true, value: "nro_order" },
        { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total", align: "left", sortable: true, value: "imp_total" },
        { text: "Estado", align: "left", sortable: true, value: "state" },
        { text: "Cbtes Asoc.", align: "left", sortable: true },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      cbte_tipos: [],
      states: [],
      emitters: [],
      receivers: [],
      open_generate_comprobante: false,
      desde_selected_open: false,
      hasta_selected_open: false,
      voucher_number_selected: null,
      emitter_selected: null,
      receiver_selected: null,
      state_selected: null,
      cbte_tipo_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      invoice_to_cancel: null,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    closeDialog() {
      this.item_dialog = null;
      this.title_dialog = "";
      this.message_dialog = "";
      this.loading_dialog = false;
      this.dialog_open_afip = false;
    },
    openDialogSendToAFIP(item) {
      this.title_dialog = "Envio a AFIP " + item.cbte_tipo_name;
      this.message_dialog = "¿Esta seguro que desea enviar el comprobante " + item.cbte_tipo_name + " del " + item.creation_date + " a la AFIP?";
      this.loading_dialog = false;
      this.item_dialog = item;
      this.dialog_open_afip = true;
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected + " 00:00:00",
        to: this.hasta_selected + " 23:59:59",
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
        receiver: this.receiver_selected != null ? this.receiver_selected.id : null,
        state: this.state_selected != null ? this.state_selected.id : null,
        voucher_number: this.voucher_number_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      emmiterService.filter().then((response) => {
        this.emitters = response.result;
      });
      receiversService.filter({}).then((response) => {
        this.receivers = response.result;
      });
      billingGeneralService.cbteTiposInvoice(null, null, "all").then((response) => {
        if (response.status) {
          this.cbte_tipos = response.result;
        }
      });
      billingGeneralService.list_states().then((response) => {
        this.states = response.result;
      });
    },
    exportToExcel() {      
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      this.service
        .export_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "detalles_por_conceptos.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_filter error");
          console.log(e);
          this.loading = false;
        });        
    },

    sendToAFIP() {
      this.loading_dialog = true;
      vouchersService
        .sendToAFIP(this.item_dialog.id)
        .then((response) => {
          console.log(response);
          if (response.status) {
            this.$toast.success(response.msg, "Success");
            this.loadList(this.service, this.getFilterParams());
            this.dialog_open_afip = false;
          } else {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading_dialog = false;
        });
    },
    printItem(item) {
      this.dialog_imprimiendo = true;
      vouchersService
        .print(item.id)
        .then((response) => {
          if (!generalService.downloadPdf(response, "voucher_" + item.voucher_number + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.dialog_imprimiendo = false;
        });
    },
    deleteItemConfirm() {
      this.loading = true;
      vouchersService
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("vouchersService.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },
    viewcbtesAsoc(item) {
      this.voucher_selected = item;
      this.dialog_cbtes_asoc = true;
    },
    cancelInvoice(item) {
      this.invoice_to_cancel = item;
      this.open_generate_comprobante = true;
    },    
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
