<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="$emit('closeDialog')" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-card-title class="headline">Documentos</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-3">Descargue los documentos de la tarea {{ task_name }}</label>
          
          <v-data-table
            class="pb-4 mt-3"
            :headers="headers_documents"
            :items="documents"
            style="border: solid 1px #1c1c1c"
            :hide-default-footer="true"
            :items-per-page="100"
            :loading="loading" loading-text="Cargando..."
          >
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.index + 1 }}
                </td>
                <td>{{ props.item.name }}</td>                
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="green" @click="download(props.item)"> download </v-icon>
                    </template>
                    <span>Descargar</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
          
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cerrar</v-btn>            
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConstantSection from "@/constants/sections";
import { accessUtils } from "@/libs/utils/accessUtils";
import { filesService } from "@/libs/ws/filesService";
export default {
  components: {  },
  props: {
    dialog: Boolean,
    criteria_price: Object,  
    documents: Array,
    task_name: String,
  },
  data: () => ({
    loading: false,
    headers_documents: [
      { text: "Número", value: "number", sortable: false, class: "bg-grey" },
      { text: "Nombre de archivo", value: "name", sortable: false, class: "bg-grey" },
      { text: "Acción", value: "", sortable: false, class: "bg-grey" },
    ],  
  }),
  mounted() {          
  },
  watch: {    
  },
  methods: {
    hasCreateAccessSignatory() {
      return accessUtils.getAccessOf(ConstantSection.SECTION_ORDERS_STATES).create;
    },
    download(doc){  
      this.loading = true;
      filesService.downloadFile(doc.url)
        .then(response=>{
          console.log(response.data)

          let blob = new Blob([response.data], {
              type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = doc.name;
          link.click();          
        })        
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(()=> this.loading = false);
    }  
  },
};
</script>
