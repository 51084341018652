<template>
  <div>
    <PdfModal
      :loading_save="loading_save"
      @close="dialog_pdf = false"
      @save="savePdf"
      :modal_title="modal_pdf_title"
      :dialog="dialog_pdf"
      :url="url_pdf"
      :b64img="b64img"
      :documentByA="documentSelected"
    >
    </PdfModal>

    <ModalFojas
      :dialog="dialog_foja"
      :task_name="task_name"
      :document="documentSelected"
      :signatories="signatoriesSelected"
      @closeDialog="closeDialogFoja"
      :asigned_task="asignedTaskSelected"
      :work_order="workOrderSelected"
    />

    <ModalSeal
      :dialog="dialog_seal"
      :asigned_task="asignedTaskSelected"
      :work_order="workOrderSelected"
      :document="documentSelected"
      @openModalPdf="openModalPdf"
      @closeDialog="closeDialogSeal"
      :loading="loading_seal"
    />

    <v-dialog v-model="dialog" @click:outside="$emit('closeDialog')" @keydown.esc="$emit('closeDialog')" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-card-title class="headline">Documentos</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-3">Descargue los documentos de la tarea {{ task_name }}</label>

          <v-data-table
            class="pb-4 mt-3"
            :headers="headers_documents"
            :items="documents"
            style="border: solid 1px #1c1c1c"
            :hide-default-footer="true"
            :items-per-page="100"
            :loading="loading"
            loading-text="Cargando..."
          >
            <template v-slot:item="props">
              <tr>
                <td>
                  {{ props.index + 1 }}
                </td>
                <td>{{ props.item.name }}</td>
                <td class="text-center">
                  <v-tooltip bottom v-if="asigned_task.has_attached_files">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="green" @click="download(props.item.url, props.item.name)"> download </v-icon>
                    </template>
                    <span>Descargar documento original</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="asigned_task.has_attached_files && props.item.filename_with_seal != null">
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" v-on="on" small color="blue" @click="download(props.item.url_seal, props.item.filename_with_seal)"> download </v-icon>
                    </template>
                    <span>Descargar documento con sello</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="asigned_task.has_attached_files">
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" v-on="on" small color="blue" @click="openModalSeal(props.item)"> edit </v-icon>
                    </template>
                    <span>Firmar documento</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="hasFoja()">
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" v-on="on" small color="orange" @click="openModalFoja(props.item)"> post_add </v-icon>
                    </template>
                    <span>Imprimir Foja</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="asigned_task.has_attached_files && props.item.foja_file != null">
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" v-on="on" small color="orange" @click="download(props.item.url_foja, props.item.foja_file, props.item)"> download </v-icon>
                    </template>
                    <span>Descargar Foja</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cerrar</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConstantSection from "@/constants/sections";
import { accessUtils } from "@/libs/utils/accessUtils";
import { filesService } from "@/libs/ws/filesService";
import { assignatesTaskService } from "@/libs/ws/assignatesTaskService";

import tasks_ids_with_foja from "@/constants/tasks_ids_with_foja";

import ModalFojas from "./Fojas/ModalFojas.vue";
import ModalSeal from "./Seals/ModalSeal.vue";
import PdfModal from "@/components/PdfEditor/PdfModal";

export default {
  components: { ModalFojas, PdfModal, ModalSeal },
  props: {
    dialog: Boolean,
    criteria_price: Object,
    documents: Array,
    task_name: String,
    asigned_task: Object,
    work_order: Object,
    filter: Function,
  },
  data: () => ({
    loading: false,
    loading_save: false,
    loading_seal: false,
    dialog_foja: false,
    dialog_seal: false,
    documentSelected: null,
    asignedTaskSelected: null,
    workOrderSelected: null,
    signatoriesSelected: [],
    headers_documents: [
      { text: "Número", value: "number", sortable: false, class: "bg-grey" },
      { text: "Nombre de archivo", value: "name", sortable: false, class: "bg-grey" },
      { text: "Acción", value: "", sortable: false, class: "bg-grey", align: "center" },
    ],

    modal_pdf_title: "",
    dialog_pdf: false,
    url_pdf: "",
    b64img: "",
  }),
  mounted() {},
  watch: {},
  methods: {
    hasCreateAccessSignatory() {
      return accessUtils.getAccessOf(ConstantSection.SECTION_ORDERS_STATES).create;
    },
    hasFoja() {
      return this.asigned_task != null && tasks_ids_with_foja.includes(this.asigned_task.task.id);
    },
    download(url, name, item) {
      console.log(item);
      this.loading = true;
      filesService
        .downloadFile(url)
        .then((response) => {
          console.log(response.data);

          let blob = new Blob([response.data], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => (this.loading = false));
    },
    openModalFoja(document) {
      this.documentSelected = document;
      this.asignedTaskSelected = this.asigned_task;
      this.workOrderSelected = this.work_order;
      this.signatoriesSelected = this.asigned_task.signatories;
      this.dialog_foja = true;
    },
    closeDialogFoja() {
      this.documentSelected = null;
      this.dialog_foja = false;
    },
    openModalSeal(document) {
      this.documentSelected = document;
      this.asignedTaskSelected = this.asigned_task;
      this.workOrderSelected = this.work_order;
      this.dialog_seal = true;
    },
    closeDialogSeal() {
      this.dialog_seal = false;
    },
    openModalPdf(seal, document) {
      this.loading_seal = true;
      this.documentSelected = document;
      const currentObj = this;
      filesService
        .downloadFile(this.documentSelected.url)
        .then((fileResponse) => {
          let blob = new Blob([fileResponse.data], {
            type: "application/pdf",
          });
          let url = window.URL.createObjectURL(blob);

          assignatesTaskService
            .gen_seal(currentObj.documentSelected.assigned_task, currentObj.documentSelected.id, seal)
            .then((response) => {
              if (response.b64seal != undefined) {
                currentObj.modal_pdf_title = "Agregado de sello de foja: " + currentObj.documentSelected.name;
                currentObj.url_pdf = url;
                currentObj.b64img = response.b64seal;
                currentObj.dialog_pdf = true;
                this.$emit("filter");
              } else {
                this.$toast.error(response.msg, "Error");
              }
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => (currentObj.loading_seal = false));
        })
        .catch((e) => {
          currentObj.loading_seal = false;
        });
    },
    savePdf(pdfBufferArray, documentByA) {
      this.loading_save = true;
      let blob = new Blob([pdfBufferArray], { type: "application/pdf" });
      let file = new File([blob], "test.pdf");

      var formData = new FormData();
      formData.append("pdf_with_seal", file);
      formData.append("document_id", documentByA.id);

      this.loading = true;
      assignatesTaskService
        .save_seal(formData)
        .then((response) => {
          if (response.status) {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "test.pdf";
            link.click();
            this.$emit("filter");
            this.$emit("closeDialog");
            this.dialog_pdf = false;
            this.dialog_seal = false;
            return;
          }
          this.$toast.error(response.msg, "Error");
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error(e.message, "Error");
        })
        .finally(() => {
          this.loading = false;
          this.loading_save = false;
        });
    },
  },
};
</script>
