<template>
    <v-dialog v-model="dialog_history" max-width="600px" @keydown.esc="close()">
        <v-card>
            <v-card-title>
                <span class="headline"
                    >Historial de Estados {{ task_name }}
                </span>
            </v-card-title>

            <v-card-text>
                <v-data-table :headers="headers" :items="history" item-key="id">
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item.datetime }}</td>
                            <td
                                style="min-width:135px"
                                v-html="props.item.state.name"
                            ></td>                            
                            <td>
                                {{
                                    props.item.user != null
                                        ? props.item.user.username
                                        : "-"
                                }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="close()">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { assignatesTaskService } from "@/libs/ws/assignatesTaskService";
export default {
    name: "HistoryModal",
    props: {},
    data() {
        return {            
            dialog_history: false,
            history: [],
            task_name: "",
            mainItem: {
                id: 0,
                datetime: "",
                state: "",
                user: { id: 0, username: "" },
                descripcion: ""
            },
            defaultItem: {
                id: 0,
                datetime: "",
                state: "",
                user: { id: 0, username: "" },
                descripcion: ""
            },
            headers: [
                {
                    text: "Fecha",
                    align: "left",
                    sortable: true,
                    value: "fecha"
                },
                { text: "Estado", value: "state.name" },                
                { text: "Usuario", value: "user.username" }
            ]
        };
    },
    mounted() {},
    computed: {},
    methods: {
        resetObject() {
            this.mainItem = Object.assign({}, this.defaultItem);
        },
        close() {
            this.dialog_history = false;
            this.resetObject();
        },
        open(assigned_id,task_name) {
            this.task_name = task_name;
            this.get_history(assigned_id);
        },
        get_history(assigned_id) {            
            if (assigned_id > 0) {
                
                assignatesTaskService.get_history_state_assigned_task(assigned_id)
                    .then(response => {    
                        console.log(response)
                        this.history = response.result;
                        this.dialog_history = true;
                    })
                    .catch(function(error) {
                        alert(error.request);
                        this.loading = false;
                    });
            }
        },        
    }
};
</script>
