<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="name" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <v-text-field autocomplete="off" data-vv-as="spent" label="Gasto" name="spent" v-validate="'decimal|required'" v-model="item.spent" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("spent") }}</span>

                <v-text-field autocomplete="off" data-vv-as="honorary" label="Honorario" name="honorary" v-validate="'decimal|required'" v-model="item.honorary" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("honorary") }}</span>

                <div>
                  <label class="fs-16">Categoría de la tarea</label>
                  <v-combobox
                    v-model="item.category"
                    data-vv-as="category"
                    name="category"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>

                <v-text-field v-if="item.category != null && item.category.id == 'P'" autocomplete="off" data-vv-as="spent_notarial_contribution" label="Aporte notarial" name="spent_notarial_contribution" v-validate="'decimal|required'" v-model="item.spent_notarial_contribution" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("spent_notarial_contribution") }}</span>

                <v-text-field v-if="item.category != null && item.category.id == 'P'" autocomplete="off" data-vv-as="spent_writing_right" label="Derecho de escritura" name="spent_writing_right" v-validate="'decimal|required'" v-model="item.spent_writing_right" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("spent_writing_right") }}</span>

                <div class="row mt-0">
                  <div class="col-md-12">
                    <label class="fs-16">¿La tarea requiere firmantes?</label>
                    <v-radio-group class="pb-0" v-model="item.has_signatories" :disabled="isViewModal()">
                      <v-radio v-for="obj in yesno_group" :key="obj.id" :label="obj.name" :value="obj.id"></v-radio>
                    </v-radio-group>
                  </div>
                </div>

                <div class="row mt-0">
                  <div class="col-md-12">
                    <label class="fs-16">¿La tarea posee un valor diferenciado por cada adicional?</label>
                    <v-radio-group v-model="item.has_different_aditional_value" :disabled="isViewModal()">
                      <v-radio v-for="obj in yesno_group" :key="obj.id" :label="obj.name" :value="obj.id"></v-radio>
                    </v-radio-group>
                  </div>
                </div>

                <v-text-field
                  v-if="item.has_different_aditional_value"
                  autocomplete="off"
                  data-vv-as="aditional_spent"
                  label="Gasto por adicional"
                  name="aditional_spent"
                  v-validate="'decimal|required'"
                  v-model="item.aditional_spent"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("aditional_spent") }}</span>

                <v-text-field
                  v-if="item.has_different_aditional_value"
                  autocomplete="off"
                  data-vv-as="aditional_honorary"
                  label="Honorario por adicional"
                  name="aditional_honorary"
                  v-validate="'decimal|required'"
                  v-model="item.aditional_honorary"
                  :disabled="isViewModal()"
                ></v-text-field>
                <span class="red--text">{{ errors.first("aditional_honorary") }}</span>

                <div>
                  <label class="fs-16">Indique cual es el criterio utilizado para calcular el valor</label>
                  <v-combobox
                    v-model="item.criteria_price"
                    data-vv-as="criteria_price"
                    name="criteria_price"
                    :items="criterio_utilizado"
                    item-text="name"
                    item-value="id"
                    autocomplete="off"
                    :disabled="isViewModal()"
                  ></v-combobox>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";

export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      roles: [],
      clients: [],
      yesno_group: [
        { id: true, name: "Si" },
        { id: false, name: "No" },
      ],
      criterio_utilizado: [
        { id: 'N', name: "No aplica" },
        { id: 'D', name: "Solo documentos" },
        { id: 'H', name: "Cantidad de hojas y documentos" },
        { id: 'F', name: "Cantidad de firmantes y documentos" },
      ],
      categories: [
        { id: 'C', name: "Certificaciones" },
        { id: 'P', name: "Poderes" },        
        { id: 'R', name: "Pedidos de Informes" },   
      ],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {},
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();          
          //Se crea data para reemplazar criteria_price co nel id
          const data = {
            id: this.item.id,
            name: this.item.name,
            spent: this.item.spent,
            honorary: this.item.honorary,
            aditional_spent: this.item.aditional_spent,
            aditional_honorary: this.item.aditional_honorary,
            has_signatories: this.item.has_signatories,
            criteria_price: this.item.criteria_price.id,
            has_different_aditional_value: this.item.has_different_aditional_value,

            category: this.item.category.id,
            spent_notarial_contribution: this.item.spent_notarial_contribution,
            spent_writing_right: this.item.spent_writing_right,
          }
          
          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              });
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              });
          }          
        }
      });
    },
  },
};
</script>
