<template>
  <div>
    <v-dialog v-model="open" max-width="900" @keydown.esc="close()" @click:outside="close()">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>

        <v-card-text>
          <p class="text-h6 mb-5">Complete el formulario para crear un comprobante</p>

          <div class="row">
            <div class="col-md-12">
              <v-row>
                <v-col md="6">
                  <v-combobox
                    v-model="invoice.emitter"
                    data-vv-as="emitter"
                    name="emitter"
                    item-text="name"
                    item-value="id"
                    :items="emitters"
                    label="Emisor*"
                    autocomplete="off"
                    v-validate="'required'"
                    v-on:change="
                      emitterChangeCombo($event);
                      getCbtesTipos();
                    "
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("emitter") }}</span>
                </v-col>

                <v-col md="6">
                  <v-combobox
                    v-model="invoice.receiver"
                    data-vv-as="receiver"
                    name="receiver"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("receiver") }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6">
                  <v-combobox
                    v-model="invoice.cbte_tipo"
                    data-vv-as="cbte_tipo"
                    name="cbte_tipo"
                    item-text="Desc"
                    item-value="Id"
                    :items="cbte_tipos"
                    label="Tipo de comprobante*"
                    autocomplete="off"
                    v-validate="'required'"
                    class="overflow-auto"
                    filled
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("cbte_tipo") }}</span>
                </v-col>

                <v-col md="3">
                  <v-combobox v-model="invoice.pto_vta" data-vv-as="pto_vta" name="pto_vta" :items="ptosvtas" label="Punto de venta*" autocomplete="off" v-validate="'required'"></v-combobox>
                  <span class="red--text">{{ errors.first("pto_vta") }}</span>
                </v-col>

                <v-col md="3">
                  <DatePickerEsp
                    :initToday="true"
                    label="Fecha"
                    :date="invoice.cbte_fch"
                    :isRequired="true"
                    name="cbte_fch"
                    @setDate="
                      (date) => {
                        invoice.cbte_fch = date;
                      }
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <DatePickerEsp
                    :initToday="true"
                    label="Periodo desde (Min: -10 días de la fecha de emisión)"
                    :date="invoice.cbte_fch_serv_desde"
                    :isRequired="true"
                    name="cbte_fch_serv_desde"
                    @setDate="
                      (date) => {
                        invoice.cbte_fch_serv_desde = date;
                      }
                    "
                  />
                </v-col>
                <v-col md="4">
                  <DatePickerEsp
                    :initToday="true"
                    label="Periodo hasta (Max: +10 días de la fecha de emisión)"
                    :date="invoice.cbte_fch_serv_hasta"
                    :isRequired="true"
                    name="cbte_fch_serv_hasta"
                    @setDate="
                      (date) => {
                        invoice.cbte_fch_serv_hasta = date;
                      }
                    "
                  />
                </v-col>
                <v-col md="4">
                  <DatePickerEsp
                    :initToday="true"
                    label="Fecha Vto. Pago"
                    :date="invoice.fch_vto_pago"
                    :isRequired="true"
                    name="fch_vto_pago"
                    @setDate="
                      (date) => {
                        invoice.fch_vto_pago = date;
                      }
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <v-combobox
                    v-model="invoice.concepto"
                    data-vv-as="concepto"
                    name="concepto"
                    item-text="Desc"
                    item-value="Id"
                    :items="conceptos"
                    label="Concepto*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("concepto") }}</span>
                </v-col>

                <v-col md="4">
                  <v-combobox
                    v-model="invoice.moneda"
                    data-vv-as="moneda"
                    name="moneda"
                    item-text="Desc"
                    item-value="Id"
                    :items="monedas"
                    label="Moneda*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("moneda") }}</span>
                </v-col>

                <v-col md="4">
                  <v-text-field
                    v-if="invoice.moneda != null && invoice.moneda.Id !== 'PES'"
                    autocomplete="off"
                    data-vv-as="mon_cotiz"
                    label="Cotización de la moneda*"
                    name="mon_cotiz"
                    v-validate="'decimal|required'"
                    v-model="invoice.mon_cotiz"
                  >
                  </v-text-field>
                  <span class="red--text">{{ errors.first("mon_cotiz") }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <v-combobox
                    v-model="invoice.condition_vta"
                    data-vv-as="condition_vta"
                    name="condition_vta"
                    :items="conditions"
                    label="Condicion*"
                    autocomplete="off"
                    v-validate="'required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("condition_vta") }}</span>
                </v-col>
              </v-row>

              <!-- COMPROBANTE ASOCIADO -->
              <v-row v-if="showCbteAsociado()">
                <v-col md="12">
                  <h6>Comprobante asociado:</h6>
                </v-col>
              </v-row>
              <v-row v-if="showCbteAsociado()">
                <v-col md="5">
                  <v-combobox
                    v-model="cbte_tipo_asoc"
                    data-vv-as="cbte_tipo_asoc"
                    name="cbte_tipo_asoc"
                    item-text="Desc"
                    item-value="Id"
                    :items="cbte_tipos_invoice"
                    label="Tipo de comprobante asociado*"
                    autocomplete="off"
                    v-validate="'required'"
                    class="overflow-auto"
                    filled
                    @change="checkCbteAsociado()"
                  ></v-combobox>
                </v-col>
                <v-col md="2">
                  <v-text-field
                    v-if="true"
                    autocomplete="off"
                    data-vv-as="pto_vta_asoc"
                    label="Nº pto vta*"
                    name="pto_vta_asoc"
                    v-validate="'decimal|required'"
                    v-model="pto_vta_asoc"
                    :loading="loading_voucher_number_asoc"
                    @change="checkCbteAsociado()"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field
                    v-if="true"
                    autocomplete="off"
                    data-vv-as="mon_cotiz"
                    label="Nº de factura*"
                    name="mon_cotiz"
                    v-validate="'decimal|required'"
                    v-model="voucher_number_asoc"
                    :loading="loading_voucher_number_asoc"
                    @change="checkCbteAsociado()"
                  >
                  </v-text-field>
                </v-col>
                <v-col md="2" style="text-align: left; margin-top: 20px">
                  <div v-if="result_cbte_asoc">
                    <v-icon v-if="cbteAsociadoResult()" medium color="green" class=""> check </v-icon>
                    <v-icon v-if="!cbteAsociadoResult()" medium color="red" class=""> close </v-icon>
                    <span v-if="!cbteAsociadoResult()" style="color: red">No se encontro el comprobante</span>
                  </div>
                </v-col>
              </v-row>

              <!-- ITEMS MANUALES -->
              <v-row>
                <v-col md="12">
                  <b>Detalles</b>

                  <v-btn small color="blue-grey" class="ml-5 text-white" @click="addCustomDetail()">
                    <v-icon big color="green"> add </v-icon>
                    <span>Agregar</span>
                  </v-btn>
                </v-col>
                <v-col md="12">
                  <v-data-table class="pb-4" :headers="header_custome" :items="this.invoice.custom_items" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
                    <template v-slot:item="props">
                      <tr>
                        <td>
                          <v-text-field autocomplete="off" name="desc" placeholder="Descripción*" dense type="text" v-model="props.item.desc" class="mt-3" />
                          <span class="red--text">{{ errors.first("description") }}</span>
                        </td>
                        <td>
                          <v-text-field autocomplete="off" name="quantity" placeholder="Cantidad*" dense v-validate="'decimal'" type="number" v-model="props.item.quantity" class="mt-3" />
                          <span class="red--text">{{ errors.first("quantity") }}</span>
                        </td>
                        <td>
                          <v-text-field autocomplete="off" name="price" placeholder="Precio*" dense v-validate="'decimal'" type="number" v-model="props.item.price" class="mt-3" />
                          <span class="red--text">{{ errors.first("price") }}</span>
                        </td>
                        <td>
                          <v-combobox
                            v-model="props.item.iva"
                            data-vv-as="iva"
                            name="iva"
                            item-text="Desc"
                            item-value="Id"
                            :items="ivas"
                            label="IVA*"
                            autocomplete="off"
                            v-validate="'required'"
                          ></v-combobox>
                          <span class="red--text">{{ errors.first("iva") }}</span>
                        </td>
                        <td class="text-right">{{ showTotalCustomeItem(props.item) }}</td>
                        <td class="text-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'custom')"> indeterminate_check_box </v-icon>
                            </template>
                            <span>Remover</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4"> Total IVA: {{ formatUtils.formatPrice(imp_iva) }} </v-col>
                <v-col md="4"> Total Gravado: {{ formatUtils.formatPrice(imp_neto) }} </v-col>
                <v-col md="4"> Total No Gravado: {{ formatUtils.formatPrice(imp_tot_conc) }} </v-col>
              </v-row>
              <v-row>
                <v-col md="12">
                  <b>Total: {{ formatUtils.formatPrice(imp_total) }}</b>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>

          <v-btn color="green darken-1" text @click="generateInvoice" :loading="loading"> Generar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="dialog_loading" text="Cargando..." />
  </div>
</template>
<script>
import Constant from "@/constants/sections";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { receiversService } from "@/libs/ws/receiversService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
import DialogLoading from "@/components/DialogLoading.vue";

export default {
  components: { DatePickerEsp, DialogLoading },
  props: {
    title: String,
    checkbox_label: String,
    open: Boolean,
    reload: Function,
    cancel: Function,
    loading: {
      default: false,
      checkbox: false,
      type: Boolean,
    },
    invoice_to_cancel: {
      default: null,
      type: Object,
    },
  },
  data: () => ({
    dialog_loading: false,
    formatUtils: formatUtils,
    checkbox: false,
    cbte_tipos_invoice: [],
    emitters: [],
    receivers: [],
    cbte_tipos: [],
    ptosvtas: [],
    conceptos: [],
    monedas: [],
    ivas: [],
    voucher_number_asoc: null,
    pto_vta_asoc: null,
    cbte_tipo_asoc: null,
    loading_voucher_number_asoc: false,
    result_cbte_asoc: false,
    invoice: {
      emitter: null,
      receiver: null,
      cbte_tipo: null,
      pto_vta: null,
      cbte_fch: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_desde: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_hasta: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      fch_vto_pago: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      remitos: [],
      custom_items: [],
      cbte_asoc: null,
    },
    //Se separaron de invoice para poder usar el watch sin hacer un bucle infinito
    imp_total: 0,
    imp_neto: 0,
    imp_tot_conc: 0,
    imp_iva: 0,
    defaultInvoice: {
      emitter: null,
      receiver: null,
      cbte_tipo: null,
      pto_vta: null,
      cbte_fch: null,
      cbte_fch_serv_desde: null,
      cbte_fch_serv_hasta: null,
      fch_vto_pago: null,
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      remitos: [],
      custom_items: [],
    },
    header_custome: [
      { text: "Descripción", value: "description", sortable: false, class: "bg-grey" },
      { text: "Cantidad", value: "quantity", sortable: false, class: "bg-grey" },
      { text: "Precio", value: "price" },
      { text: "IVA", value: "iva" },
      { text: "Total", value: "total" },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    loading_combos: {
      cbte_tipos_invoice: false,
      emitters: false,
      receivers: false,
      cbte_tipos: false,
      ptosvtas: false,
      conceptos: false,
      monedas: false,
      ivas: false,
    },
    conditions: ["Contado", "Cuenta corriente"],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.init();
      }
    },
    invoice: {
      handler: function (val) {
        this.calculateTotal();
      },
      deep: true,
    },
    loading_combos: {
      handler: function (val) {
        if (this.isFinishedLoadingCombos()) {
          this.dialog_loading = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboData();
    this.defaultInvoice = JSON.parse(JSON.stringify(this.invoice));
  },
  methods: {
    isFinishedLoadingCombos() {
      return (
        this.loading_combos["cbte_tipos_invoice"] == false &&
        this.loading_combos["emitters"] == false &&
        this.loading_combos["receivers"] == false &&
        this.loading_combos["cbte_tipos"] == false &&
        this.loading_combos["ptosvtas"] == false &&
        this.loading_combos["conceptos"] == false &&
        this.loading_combos["monedas"] == false &&
        this.loading_combos["ivas"] == false
      );
    },
    init() {
      this.errors.clear();
      this.$validator.reset();
      this.dialog_loading = true;
      //TODO: reinitialize
      if (this.invoice_to_cancel != null) {
        //this.invoice = this.invoice_to_cancel;

        this.invoice.emitter = this.invoice_to_cancel.emitter;
        this.invoice.receiver = this.invoice_to_cancel.receiver;
        this.invoice.remitos = [];
        //this.invoice.cbte_asoc = this.invoice_to_cancel;
        this.voucher_number_asoc = this.invoice_to_cancel.voucher_number;
        this.pto_vta_asoc = this.invoice_to_cancel.pto_vta;
        this.cbte_tipo_asoc = { Id: this.invoice_to_cancel.cbte_tipo };
        this.checkCbteAsociado();

        this.pto_vta_asoc = this.invoice_to_cancel.pto_vta;
        this.voucher_number_asoc = this.invoice_to_cancel.voucher_number;

        this.invoice.cbte_fch = this.invoice_to_cancel.cbte_fch;
        this.invoice.cbte_fch_serv_desde = this.invoice_to_cancel.cbte_fch_serv_desde;
        this.invoice.cbte_fch_serv_hasta = this.invoice_to_cancel.cbte_fch_serv_hasta;
        this.invoice.fch_vto_pago = this.invoice_to_cancel.fch_vto_pago;
        this.invoice.mon_cotiz = this.invoice_to_cancel.mon_cotiz;

        this.emitterChangeCombo();
      } else {
        this.dialog_loading = false;
      }
    },
    loadItemsFromInvoiceCancel() {
      let items = {};
      if (this.invoice_to_cancel != null) {
        this.invoice_to_cancel.items_custom.forEach((item) => {
          if (!Object.hasOwn(items, item.iva_id)) {
            items[item.iva_id] = {
              desc: "Credito por factura " + this.invoice_to_cancel.voucher_number,
              quantity: 1, //No se aument ala cantidad porque el total va por el precio
              price: 0,
              iva: this.selectComboId(this.ivas, item.iva_id, "Id"),
              total: 0, //el total lo calcula el frontend con iva
            };
          }
          items[item.iva_id].price += parseFloat(item.price);
        });

        this.invoice_to_cancel.items_remitos.forEach((item) => {
          if (parseFloat(item.grouped_items.no_taxed_total) > 0) {
            //Agregar parte no gravada
            if (!Object.hasOwn(items, vouchersService.IVA_ID_0)) {
              items[vouchersService.IVA_ID_0] = {
                desc: "Credito por factura " + this.invoice_to_cancel.voucher_number,
                quantity: 1, //No se aument ala cantidad porque el total va por el precio
                price: 0,
                iva: this.selectComboId(this.ivas, vouchersService.IVA_ID_0, "Id"),
                total: 0, //el total lo calcula el frontend con iva
              };
            }
            items[vouchersService.IVA_ID_0].price += parseFloat(item.grouped_items.no_taxed_total);
          }

          if (parseFloat(item.grouped_items.taxed_total) > 0) {
            //Agregar parte no gravada
            if (!Object.hasOwn(items, vouchersService.IVA_ID_21)) {
              items[vouchersService.IVA_ID_21] = {
                desc: "Credito por factura " + this.invoice_to_cancel.voucher_number,
                quantity: 1, //No se aument ala cantidad porque el total va por el precio
                price: 0,
                iva: this.selectComboId(this.ivas, vouchersService.IVA_ID_21, "Id"),
                total: 0, //el total lo calcula el frontend con iva
              };
            }
            items[vouchersService.IVA_ID_21].price += parseFloat(item.grouped_items.taxed_total);
          }
        });

        Object.keys(items).forEach((key) => {
          this.invoice.custom_items.push(items[key]);
        });
        //this.dialog_loading = false;
      }
    },
    close() {
      this.invoice_to_cancel = null;
      this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
      this.$emit("cancel");
      this.errors.clear();
    },
    showCbteAsociado() {
      const Ids = this.cbte_tipos_invoice.map((a) => a.Id);
      return this.invoice.cbte_tipo != null && this.invoice.cbte_tipo.Id > 0 && !Ids.includes(this.invoice.cbte_tipo.Id);
    },
    loadComboData() {
      emmiterService.filter().then((response) => {
        this.emitters = response.result;
      });
      receiversService.filter({}).then((response) => {
        this.receivers = response.result;
      });
      if (this.invoice.emitter != null && this.invoice.emitter.id > 0) this.emitterChangeCombo();
    },

    getCbtesTipos() {
      if (this.invoice.emitter != null && this.invoice.emitter.id > 0) {
        this.loading_combos["cbte_tipos_invoice"] = true;
        //Se Pide para saber si el comprobante seleccionado a emitir requiere comprobantes asociados (No es factura)
        billingGeneralService.cbteTiposInvoice(this.invoice.emitter.id, "invoice").then((response) => {
          if (response.status) {
            this.cbte_tipos_invoice = response.result;
            this.loading_combos["cbte_tipos_invoice"] = false;

            if (this.invoice_to_cancel != null) this.cbte_tipo_asoc = this.selectComboId(this.cbte_tipos_invoice, this.invoice_to_cancel.cbte_tipo, "Id");
          }
        });

        this.loading_combos["cbte_tipos"] = true;
        billingGeneralService.cbteTiposInvoice(this.invoice.emitter.id, "all").then((response) => {
          if (response.status) {
            this.cbte_tipos = response.result;
            this.loading_combos["cbte_tipos"] = false;
            this.invoice.cbte_tipo = response.result[0];
            if (this.invoice_to_cancel != null) this.invoice.cbte_tipo = this.selectComboId(this.cbte_tipos, vouchersService.getCancelIdForCbteTipo(this.invoice_to_cancel.cbte_tipo), "Id");
          }
        });
      }
    },
    receiverChangeCombo(event) {
      this.getCbtesTipos();
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
    emitterChangeCombo(event) {
      this.dialog_loading = true;
      if (this.invoice.emitter != null && this.invoice.emitter.id) {
        this.getCbtesTipos();
        this.loading_combos["ptosvtas"] = true;
        emmiterService.ptosvtas(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.ptosvtas = response.result;
            this.loading_combos["ptosvtas"] = false;
            this.invoice.pto_vta = response.result[0];
          }
        });

        this.loading_combos["conceptos"] = true;
        emmiterService.conceptos(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.loading_combos["conceptos"] = false;
            this.conceptos = response.result;
            this.invoice.concepto = response.result[1]; //Servicios
          }
        });

        this.loading_combos["monedas"] = true;
        emmiterService.monedas(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.monedas = response.result;
            this.loading_combos["monedas"] = false;
            this.invoice.moneda = response.result[0];
            if (this.invoice_to_cancel != null) this.selectComboId(this.monedas, this.invoice_to_cancel.moneda, "Id");
          }
        });

        this.loading_combos["ivas"] = true;
        emmiterService.ivas(this.invoice.emitter.id).then((response) => {
          if (response.status) {
            this.ivas = response.result;
            this.loading_combos["ivas"] = false;
            this.loadItemsFromInvoiceCancel();
          }
        });
      }
    },
    removeDetail(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.invoice.custom_items.indexOf(item);
        if (index_item > -1) this.invoice.custom_items.splice(index_item, 1);
      }
    },
    generateInvoice() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          let data = this.getInvoiceParams();
          vouchersService
            .create(data)
            .then((response) => {
              if (response.status) {
                this.$toast.success("La Facturase generó correctamente", "OK");
                this.$emit("updateDialog", false);
                this.$emit("reload");
                this.close();
                this.invoice = JSON.parse(JSON.stringify(this.defaultInvoice));
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$toast.error("Complete los campos requeridos", "Error");
        }
      });
    },
    getInvoiceParams() {
      let data = {
        emitter_id: this.invoice.emitter.id,
        receiver_id: this.invoice.receiver.id,
        cbte_tipo: this.invoice.cbte_tipo.Id,
        pto_vta: this.invoice.pto_vta,
        cbte_fch: this.invoice.cbte_fch,
        cbte_fch_serv_desde: this.invoice.cbte_fch_serv_desde,
        cbte_fch_serv_hasta: this.invoice.cbte_fch_serv_hasta,
        fch_vto_pago: this.invoice.fch_vto_pago,
        concepto: this.invoice.concepto.Id,
        mon_id: this.invoice.moneda.Id,
        mon_cotiz: this.invoice.moneda.Id == "PES" ? 1 : this.invoice.mon_cotiz,

        condition_vta: this.invoice.condition_vta,

        imp_total: this.imp_total,
        imp_neto: this.imp_neto,
        imp_tot_conc: this.imp_tot_conc,
        imp_iva: this.imp_iva,
        imp_op_ex: 0,
        imp_trib: 0,

        custom_items_arr: [],
        cbte_asoc: this.invoice.cbte_asoc != null ? this.invoice.cbte_asoc.id : null,
      };

      this.invoice.custom_items.forEach((item) => {
        const item_data = this.calculateCustomeItemTotals(item);
        data.custom_items_arr.push({
          desc: item.desc,
          iva_id: item.iva.Id,
          iva_desc: item.iva.Desc,
          imp_neto: item_data.imp_neto,
          imp_iva: item_data.imp_iva,
          price: item.price,
          quantity: item.quantity,
        });
      });

      return data;
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price);
      let imp_iva = item.iva == null || item.iva.id == vouchersService.IVA_ID_0 ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },
    showTotalCustomeItem(item) {
      let totals = this.calculateCustomeItemTotals(item);
      return formatUtils.formatPrice(totals.total);
    },
    calculateTotal() {
      this.imp_total = 0.0;
      this.imp_neto = 0;
      this.imp_tot_conc = 0;
      this.imp_iva = 0;

      this.invoice.custom_items.forEach((item) => {
        let totals = this.calculateCustomeItemTotals(item);
        this.imp_tot_conc += totals.imp_iva == 0 ? totals.imp_neto : 0;
        this.imp_iva += totals.imp_iva;
        if (item.iva == null || item.iva.Id != 3) this.imp_neto += totals.imp_neto;
        this.imp_total += formatUtils.formatPriceNumber(totals.imp_neto) + formatUtils.formatPriceNumber(totals.imp_iva);
      });
    },
    addCustomDetail() {
      this.invoice.custom_items.push({
        desc: "",
        quantity: 0,
        price: 0,
        iva: null,
      });
    },
    checkCbteAsociado() {
      this.invoice.cbte_asoc = null; //Reset Asoc
      this.result_cbte_asoc = false;

      if (this.voucher_number_asoc != null && this.pto_vta_asoc != null && this.cbte_tipo_asoc != null && this.cbte_tipo_asoc.Id > 0) {
        this.loading_voucher_number_asoc = true;
        vouchersService
          .getVoucherAsoc(this.invoice.emitter.id, this.voucher_number_asoc, this.pto_vta_asoc, this.cbte_tipo_asoc.Id)
          .then((response) => {
            if (response.status) {
              this.invoice.cbte_asoc = response.result;
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.$toast.error(response.message, "Error");
              } else {
                this.$toast.error(response.msg, "Error");
              }
            }
          })
          .finally(() => {
            this.loading_voucher_number_asoc = false;
            this.result_cbte_asoc = true;
          });
      }
    },
    cbteAsociadoResult() {
      return this.invoice.cbte_asoc != null && this.invoice.cbte_asoc.voucher_number > 0 && this.invoice.cbte_asoc.cbte_tipo > 0;
    },
  },
};
</script>
