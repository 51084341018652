<template>
  <div>
    <div class="col-md-12 pt-0">
      <div :class="!menu ? 'row box-chofer enCurso' : 'row box-chofer-open enCurso'" style="cursor: default !important">
        <div class="col-md-1 col-xs-12 center-vh">
          <font-awesome-icon icon="chevron-down" class="cursor" style="color: rgb(52, 131, 250)" @click="menu = true" v-if="!menu" />
          <font-awesome-icon icon="chevron-up" class="cursor" style="color: rgb(52, 131, 250)" @click="menu = false" v-else />
          <p style="white-space: nowrap" class="mb-0 ml-2">
            <b>#{{ order.id }}</b>
          </p>
        </div>
        <div class="col-md-2 col-xs-6 subBoxChofer">
          <p class="mb-0"><b>Fecha</b></p>
          <span style="text-transform: uppercase">{{ order.asignation_date }}</span>
        </div>
        <div class="col-md-2 col-xs-6 subBoxChofer">
          <p class="mb-0">
            <b>Ejecutante</b>
          </p>
          <span style="text-transform: uppercase">{{ order.employee != null ? order.employee.name : "Empleado" }}</span>
        </div>
        <div class="col-md-3 col-xs-6 subBoxChofer">
          <p class="mb-0"><b>Cliente | Solicitante</b></p>
          <span>{{ order.client.business_name }} | {{ (order.applicant != null)?order.applicant.name:"-" }}</span>
        </div>
        <div class="col-md-2 col-xs-6 text-center">
          <apexchart v-if="order.stats.not_finished > 0" ref="Chart01" type="donut" width="90" height="90" :options="chart01Options" :series="series01" />
          <apexchart v-else ref="Chart01" type="donut" width="90" height="90" :options="chart02Options" :series="series01" />
        </div>

        <div class="col-md-2 col-xs-6 center-vh" v-html="service.getStateHtml(order.state)"></div>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="menu" class="extra-info-monitoring mb-5">
        <div class="row">
          <div class="col-md-12">
            <v-data-table
              class="background-color: grey lighten-3"
              :headers="headersTasks"
              :items="order.asignated_tasks"
              :search="search"
              :loading-text="'Cargando'"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item="props">
                <tr>
                  <td>{{ props.index + 1 }}</td>
                  <td>{{ props.item.task.name }}</td>
                  <td>{{ props.item.page_nr }}</td>
                  <td>{{ props.item.record_nr }}</td>

                  <td :key="props.item.documents.length">
                    {{ props.item.documents.length }}
                  </td>
                  <td>{{ props.item.signatories.length }}</td>
                  <td class="text-center">{{ props.item.priority_name }}</td>
                  <td class="text-center">{{ props.item.state.name }}</td>
                </tr>
              </template>
              <template v-slot:header="props">
                <thead>
                  <tr>
                    <th colspan="1">Numero</th>
                    <th colspan="1">Tarea</th>
                    <th colspan="4" class="text-center">Informacion adicional</th>
                    <th colspan="1" class="text-center">Prioridad</th>
                    <th colspan="1" class="text-center">Estado</th>
                  </tr>
                </thead>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import GenericMixin from "@/mixins/GenericMixin.vue";
import { orderService } from "@/libs/ws/orderService";
export default {
  mixins: [GenericMixin],
  name: "OrderMonitoring",
  props: {
    order: Object,
    showDetail: { type: Boolean, default: false },
  },
  mounted() {
    var aux = Object.assign({}, this.chart01Options);
    aux.colors = ["#d4d4d4", "#d4d4d4"];
    aux.plotOptions.pie.donut.labels.value.color = "#d4d4d4";
    this.chart02Options = Object.assign({}, aux);

    this.series01 = [parseInt(this.order.stats.finished), parseInt(this.order.stats.not_finished)];
  },
  data() {
    return {
      menu: false,
      service: orderService,
      headersTasks: [
        { text: "", value: "number", sortable: false },
        { text: "", value: "task", sortable: false },
        { text: "N° Acta / Foja", value: "number_foja_acta", sortable: false, class: "bg-grey" },
        { text: "N° Libro", value: "number_libro", sortable: false, class: "bg-grey" },
        { text: "Cant. de Documentos", value: "cant_documentos", sortable: false, class: "bg-grey" },
        { text: "Cant. de Firmas", value: "cant_firmas", sortable: false, class: "bg-grey" },
        { text: "", value: "priority", sortable: false },
        { text: "", value: "state" },
      ],
      series01: [],
      chart02Options: {},
      chart01Options: {
        stroke: { show: false },
        colors: ["#f23d4f", "#d4d4d4"],
        legend: { show: false },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: { offsetY: -20 },
                value: {
                  fontSize: 16,
                  offsetY: -10,
                  color: "#f23d4f",
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "",
                  formatter: function (w) {
                    var result = 0;

                    if (parseInt(w.globals.series[0]) + parseInt(w.globals.series[1]) == 0) {
                      result = 0;
                    } else {
                      result = Math.trunc((w.globals.series[0] * 100) / (w.globals.series[0] + w.globals.series[1]));
                    }

                    return result + "%";
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {},
  methods: {},
};
</script>
